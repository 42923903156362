import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import { CreditReportCustomMetricContainerCost } from "../../graphql";
import { startCase } from "lodash";
import { useTranslation } from "@lumar/shared";
import { TFunction } from "i18next";
import { makeStyles, Tooltip, Typography } from "@material-ui/core";

export function CustomMetricContainerCostsCell(
  params: GridRenderCellParams,
): JSX.Element {
  const { t } = useTranslation("creditUsage");
  const classes = useStyles();

  const customMetricContainerCosts =
    params.value as CreditReportCustomMetricContainerCost[];

  if (!customMetricContainerCosts.length) return <></>;

  if (customMetricContainerCosts.length === 1) {
    const content = (
      <Typography variant="body2">
        {t("customMetricContainer.cost", {
          name: getName(
            t,
            customMetricContainerCosts[0].customMetricContainerName,
          ),
          cost: customMetricContainerCosts[0].cost,
        })}
      </Typography>
    );

    return <Tooltip title={content}>{content}</Tooltip>;
  }

  const content = (
    <ul className={classes.list}>
      {customMetricContainerCosts.map((x, idx) => (
        <li key={idx} className={classes.listItem}>
          <Typography variant="body2">
            {t("customMetricContainer.cost", {
              name: getName(t, x.customMetricContainerName),
              cost: x.cost,
            })}
          </Typography>
        </li>
      ))}
    </ul>
  );

  return (
    <Tooltip title={content}>
      <div className={classes.container}>{content}</div>
    </Tooltip>
  );
}

function getName(
  t: TFunction<"creditUsage">,
  customMetricContainerName: string,
): string {
  switch (customMetricContainerName) {
    case "HtmlStoring":
      return t("customMetricContainer.htmlSoring");
    case "ScreenshotStoring":
      return t("customMetricContainer.screenshotSoring");
    default:
      return startCase(customMetricContainerName);
  }
}

const useStyles = makeStyles((theme) => ({
  container: {
    margin: theme.spacing(1.5, 0),
  },
  list: {
    paddingLeft: theme.spacing(1.5),
    margin: theme.spacing(0),
  },
  listItem: {
    lineHeight: theme.typography.pxToRem(30),
  },
}));
